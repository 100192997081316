import React from 'react';
import { HomePage_CopyrightPage } from './HomePage_CopyrightPage';
import { HomePage_TitlePage } from './HomePage_TitlePage';
import styled from '@emotion/styled';

const BookLayout = styled.div({
  display: 'block',
  alignItems: 'stretch',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  [`@media (min-width: 600px)`]: {
    display: 'flex',
  },
});

export const HomePage = () => (
  // <HomePage_TitlePage />
  <BookLayout>
    <HomePage_TitlePage />
    <HomePage_CopyrightPage />
  </BookLayout>
);

import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Boilerplate } from '../../components/Boilerplate';
import { theme } from '../../../config/theme';
import { Index_Hero } from '../../components/HomePage/HomePage_TitlePage';
import { HomePage } from 'components/HomePage/HomePage';

export default ({ data: { site, allMdx } }) => {
  return (
    <Boilerplate
      site={site}
      headerColor={theme.colors.white}
      headerBg={theme.brand.primary}
    >
      <HomePage />
    </Boilerplate>
  );
};

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            # banner {
            #   childImageSharp {
            #     sizes(maxWidth: 720) {
            #       ...GatsbyImageSharpSizes
            #     }
            #   }
            # }
            slug
            keywords
          }
        }
      }
    }
  }
`;

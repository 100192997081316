import React from 'react';
import { css } from '@emotion/core';
import Link from '../Link';
import { fonts, rhythm } from '../../lib/typography';
import { theme } from '../../../config/theme';
import { LinkUnderline } from '../LinkUnderline';

const link = css`
  position: relative;
  font-size: 1.8em;
  color: ${theme.colors.headerColor};
  font-family: ${fonts.test};
  text-transform: uppercase;
  text-decoration: none;
`;

export const HomePage_TitlePage = () => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background: ${theme.colors.white};
      height: 100vh;
      font-size: 2vw;
      @media (min-width: 600px) {
        font-size: 1vw;
        order: 2;
        width: 50%;
      }
    `}
  >
    <h1
      css={css`
        font-size: 3.5em;
        letter-spacing: 2px;
        text-transform: uppercase;
      `}
    >
      <span
        css={css`
          margin-right: 0.4em;
        `}
      >
        Alisha
      </span>
      Jeddeloh
    </h1>
    <div
      css={css`
        border-top: 3px solid ${theme.colors.headerColor};
        width: 80%;
      `}
    />
    <nav
      css={css`
        margin-top: 0.5em;
        display: flex;
        justify-content: space-between;
        width: 30em;
      `}
    >
      <Link css={link} to="/about/" aria-label="Visit about page">
        About
        <LinkUnderline />
      </Link>
      <Link css={link} to="/work/" aria-label="Visit work page">
        Work
        <LinkUnderline />
      </Link>
      <Link css={link} to="/notes/" aria-label="Visit notes page">
        Notes
        <LinkUnderline />
      </Link>
    </nav>
  </div>
);

import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { fonts } from '../../lib/typography';
import { theme } from '../../../config/theme';

const LegalSection = styled.section({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  fontFamily: `${fonts.test}`,
  fontStyle: 'italic',
  // backgroundColor: '#ffffff',
  // background: `${theme.brand.secondary}`,
  // background: `${theme.colors.body_color}`,
  // background-image: url('/images/clean-gray-paper.png'),
  color: 'rgb(119, 118, 105)',
  borderRight: 'solid 2px rgb(245, 243, 235)',
  height: '100vh',
  fontSize: '3vw',
  ['@media (min-width: 600px)']: {
    width: '50%',
    fontSize: '1vw',
    order: 1,
  },
});

const Texture = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.14;
  background-image: url('/images/clean-gray-paper.png');
`;

const legalTextBase = css`
  max-width: 60%;
  font-size: 0.9em;
  line-height: 1.3em;
  text-align: center;
`;

const LegalText = styled.p`
  ${legalTextBase};
`;

const LegalTextLeft = styled.p`
  ${legalTextBase};
  text-align: left;
`;

const LegalText__emphasis = styled.span`
  font-style: normal;
  text-transform: uppercase;
`;

const Spacer__top = styled.div`
  flex-grow: 4;
`;

const Spacer__contact = styled.div`
  flex-grow: 1.5;
`;

const Spacer__flourish = styled.div`
  // flex-grow: 2.5;
  flex-grow: 1;
`;

const NumbersFlourish__leader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 2em 0 0;
  font-style: normal;
  text-transform: uppercase;
`;

const NumbersFlourish__number = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0.7em;
  font-style: normal;
  text-transform: uppercase;
`;

const NumbersFlourish = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-size: 1.1em;
  text-transform: uppercase;
  margin-bottom: 1.5em;
`;

export const HomePage_CopyrightPage = () => (
  <LegalSection>
    {/* <Texture /> */}
    <Spacer__top />
    <LegalTextLeft>
      All of the characters in this book are actual, and any resemblance to
      <br />
      fictitious persons, living or dead, is purely coincidental.
    </LegalTextLeft>
    <LegalText>
      This edition contains the complete text
      <br />
      of the original hardcover edition.
      <br />
      <LegalText__emphasis>NOT ONE WORD HAS BEEN OMITTED</LegalText__emphasis>
    </LegalText>
    <Spacer__contact />
    <LegalText>
      All rights reserved.
      <br />
      Copyright &copy; 2019 by Alisha Jeddeloh
      <br />
      For information address:
      <br />
      alishajeddeloh at gmail
    </LegalText>
    <Spacer__flourish />
    <NumbersFlourish>
      <NumbersFlourish__leader>kr</NumbersFlourish__leader>
      <NumbersFlourish__number>60</NumbersFlourish__number>
      <NumbersFlourish__number>59</NumbersFlourish__number>
      <NumbersFlourish__number>58</NumbersFlourish__number>
      <NumbersFlourish__number>57</NumbersFlourish__number>
      <NumbersFlourish__number>56</NumbersFlourish__number>
      <NumbersFlourish__number>55</NumbersFlourish__number>
      <NumbersFlourish__number>54</NumbersFlourish__number>
    </NumbersFlourish>
  </LegalSection>
);
